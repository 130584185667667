<template>
  <div>
    <div :class="question.options.length > 6 ? 'content-0to10' : 'content-0to5'">
      <app-form-rate
        v-model="inputValue"
        :items="options"
        v-on:change.native="response(inputValue)"
        item-value="value"
        item-text="label"
        :isGradient="question.gradient"
        :textColor="colors.actionText"
        :labelColor="colors.splashColor"
        :hoverColor="colors.buttonHover"
        :bgColor="colors.actionColor"
        :min-label="question.startLabel"
        :max-label="question.endLabel"
      />
    </div>
  </div>
</template>

<script>
import { gradientMaker } from '@/_helpers'
export default {
  name: 'app0to10',
  props: ['question', 'colors'],
  components: {
    'app-form-rate': () => import('@binds-tech/binds-design-system/src/components/Form/Rate')
  },
  data () {
    return {
      inputValue: null,
      options: []
    }
  },
  created () {
    this.display = false
    const optionsList = []
    this.question.options.forEach(item => {
      optionsList.push({ value: item.label, label: item.label })
    })
    this.options = optionsList
    gradientMaker.checkGradientAndReturnTheColors(this.question, this.options)
  },
  mounted () {
    this.$root.$on('footer-submit', payload => {
      if (payload) {
        this.response()
      }
    })
  },
  methods: {
    async response (value) {
      let data = {}
      value ? data = { value: value, isOther: false, text: '' } : data = { skip: true, isOther: false, text: '' }
      this.$emit('value-changed', data)
      this.$emit('question-answered', true)
    }
  }
}
</script>
<style lang="scss">
.content-0to5, .content-0to10 {
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  .rating__label--min {
    width: 100px;
    word-break: break-word;
    text-align: left;
  }
  .rating__label--max {
    width: 100px;
    word-break: break-word;
    text-align: right;
  }
}

//responsivity to mobile
@media only screen and (max-width: 850px) {
  .content-0to5, .content-0to10 {
    height: 100% !important;
  }
  .content-0to10 {
    .rating {
      .rating__item {
        margin-right: 3px;
        .rating__button {
          width: 34px;
          height: 34px;
          margin: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 404px) {
  .content-0to10 {
    .rating {
      .rating__item {
        margin-right: 4px;
        .rating__button {
          width: 24px !important;
          height: 24px !important;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
